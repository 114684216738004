#about {
    display: grid;
    font-size: 18px;
    grid-auto-rows: minmax(65px, auto);
    margin-bottom: 0;
    grid-template-columns: 8% auto 8%;
}

#about-box {
    grid-column: 2;
    display: grid;
    grid-template-columns: auto;
    grid-auto-rows: minmax(50px, auto);
}

#about > #title {
    padding-bottom: 3%;
}

.markdown-link a {
    color: #00478f;
    text-decoration: none;
}

#acknowledgements {
    grid-column: content;
    display: grid;
    grid-template-columns: auto;
    grid-auto-rows: minmax(50px, auto);
    font-size: larger;
}

#acknowledgements > #title {
    font-size: 32px;
    display: grid;
    align-items: center;
}

#acknowledgements > #intro {
    padding-top: 15px;
}

#acknowledgements-body {
    display: grid;

    grid-auto-rows: minmax(65px, auto);
  
    margin-bottom: 0;
    grid-template-columns: 7% 13% [content] auto 13% 7%;
}

.screenshot {
    text-align: center;
}

.screenshot > img {
    padding-top: 35px;
    max-width: 45%;
}

@media (max-width: 880px) {
    #about {
        font-size: 15px;
    }
    
    .guide-tabs{
        grid-template-columns: repeat(auto-fill, 134px);
    }
}
